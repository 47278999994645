@import "../lib/vegas.min.css";

#body-vegas{
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.copyright{
    z-index: 1000;
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 20px;
    color: white;
    text-shadow: 1px 1px 2px black;
}


.vegas-transition-swirlLeft,.vegas-transition-swirlLeft2 {
    transform: scale(3) rotate(35deg);
    opacity: 0
}

.vegas-transition-swirlLeft-in,.vegas-transition-swirlLeft2-in {
    transform: scale(1) rotate(0);
    opacity: 1
}

.vegas-transition-swirlLeft2-out,.vegas-transition-swirlRight,.vegas-transition-swirlRight2 {
    transform: scale(3) rotate(-35deg);
    opacity: 0
}

.vegas-transition-swirlRight-in,.vegas-transition-swirlRight2-in {
    transform: scale(1) rotate(0);
    opacity: 1
}

.vegas-transition-swirlRight2-out {
    transform: scale(3) rotate(35deg);
    opacity: 0
}