// Fonts
@font-face {
  font-family: NavFont;
  src: url(fonts/GenEiLateGoN_v2.ttf);
}

.nav-container {

  background-color: black;
  padding: 30px 0;
  height: 100px;
  box-sizing: border-box;
  text-align: center;
  font-family: NavFont;
  z-index: 10;
  height: 50px;

  @screen md {
    height: 100px;

  }

  .menu-anchor {
    display: inline-block;
    margin: 0px 5vw;
  }

  .nav-item {
    text-transform: uppercase;
    color: white;
    display: inline-block;
    padding: 0 10px;
    line-height: 40px;
    transition: 1s ease;

    &:hover, &.active {
      cursor: pointer;
      background-color: #7e00e0;
    }
  }
}

.big-icon {
  transition: 1s ease;
  background-image: url("../../public/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  padding: 10px;
  width: 100px;
  height: 100px;
  left: calc(50% - 50px);
  top: 10px;

  @screen md {
    width: 200px;
    height: 200px;
    left: calc(5vw - 50px);
    top: 0;
  }

  position: absolute;
  z-index: 11;

}

.mobile-menu {
  transition: 0.3s linear;
  max-height: 0;
  overflow: hidden;

  .list {
    padding: 50px 5px;
  }

  &.open {
    max-height: 300px;
  }

  .nav-item {
    text-transform: uppercase;
    color: white;
    display: block;
    line-height: 40px;
    transition: 1s ease;
    padding: 5px 25px;
    margin: 5px 10px;

    &:hover, &.active {
      cursor: pointer;
      background-color: #7e00e0;
    }
  }
}

.icon-nav {
  display: none;
  @screen md {
    display: block;
  }
  transition: 1s, color 2s ease;
  text-transform: uppercase;
  color: transparent;
  position: fixed;
  left: calc(5vw);
  background: black;
  width: 100px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  line-height: 100px;
  height: 0;
  //overflow: hidden;
  font-family: NavFont;
  font-size: 0;

  &.open {
    font-size: 30px;
    height: 300px;
    padding-top: 100px;
    color: white;

    &::before {
      z-index: -1;
      background: black;
      content: "";
      display: block;
      width: 70px;
      height: 70px;
      left: 15px;
      bottom: -35px;
      position: absolute;
      transform: rotate(45deg);
    }
  }
}

.mobile-menu-icon {
  transition: 1s;

  &.open {
    .big-icon{
      transform: rotate(180deg);

    }
  }
}