
.page-container{
  padding: 50px 30px;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100px;
  min-height: calc(100vh - 100px);
  @screen md{
    padding: 50px 50px 0 300px;
  }

  &.gallery{
    transition: 1s;
  }
  &.profile{
    h1 {
      @apply text-2xl;
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px solid black;
      @screen md{
        max-width: 50vw;

      }

    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
    a {
      @apply text-blue-600 underline;
    }
  }
}