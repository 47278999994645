@tailwind base;
@tailwind components;
@tailwind utilities;
html, body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

@import "navbar";
@import "index";
@import "pages";

.gallery-item {
  min-height: 100px;
  min-width: 100px;

  span {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px 30px;
    border-radius: 10px 0 0 0;
    background: rgba(255, 255, 255, 0.6);

    &.video {
      //TODO: Video Style
    }
  }
}

.btn {
  @apply cursor-pointer  px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out;
  &.active {
    @apply bg-amber-500
  }
}

.z-11 {
  z-index: 11;
}

.popup {
  //width: 60vw;
  //height: 80vh;
  iframe {
    margin: 0 auto;
    height: 65vh;
    width: 100%;
  }

  .popup-space {
    height: 80vh;
    @screen md {
      width: 80vw;
    }
    img {
      max-height: 65vh;
      margin: 0 auto;
    }
  }

}

.text-input {
  @apply block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-600 focus:outline-none;
}

.text-area {
  @apply block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-600 focus:outline-none
}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 5px grey;
  //border-radius: 10px;
  background: white;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-right: 4px white solid;
  background-clip: padding-box;
}

.accordion {
  button {
    display: block;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }

  .accordion-title {
    @apply text-xl;
    font-weight: bold;
    //border-bottom: 2px solid;

  }

  .accordion-body {
    .accordion-content {
padding:5px 10px
    }
  }
}
